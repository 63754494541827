.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  /* Centers content vertically */
    z-index: 9999;  /* Ensures the modal is above everything */

}
  

.modal-content {
    background-color: var(--dark-bg);
    padding: 40px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds a shadow for better visibility */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    gap: 20px;
}

.modal-header {
    border-radius: 5px;

}

.modal-body {

    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
  
  