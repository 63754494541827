/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

:root {
  --light-red: #d56f63;

  --orange: #d33d00;

  --red: #950028;

  --yellow: #fcab00;
  --light-green: #7b7e59;

  --dark-green: #3a4a2d;
  --background: #eaecde;

  --background-opaque: #eaecdec2;

  --main-bg: var(--background);
  --dark-bg: var(--background);

  --light-text: var(--very-light-orange);
  --medium-text: var(--medium-orange);
  --dark-text: var(--light-orange);

  --bright-detail: var(--yellow);

  --grey-color-light: rgba(232, 230, 230, 0.6);
  --grey-color: rgba(232, 230, 230, 1);
}

img {
  pointer-events: none;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body:has(.card[data-color="green"]:hover) {
  background-color: var(--background);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--orange);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--orange);
}

@layer utilities {
  .max-container {
    @apply max-w-5xl mx-auto sm:p-16 pb-12 !pt-[126px] px-8 min-h-[calc(100vh-80px)];
  }
  .orange-gradient_text {
    @apply bg-gradient-to-r from-[#B45309] to-[#FDBA74] bg-clip-text text-transparent;
  }

  .orange-gradient_bg {
    @apply bg-gradient-to-r from-[#f8b164] to-[#ffae58];
  }

  .header {
    @apply flex justify-between items-center px-8 py-4 max-w-7xl mx-auto absolute top-0 bg-transparent z-10 right-0 -left-0;
  }

  .footer {
    @apply max-w-5xl mx-auto sm:px-16 pb-6 px-8 flex flex-col gap-7;
  }

  .footer-container {
    @apply flex flex-wrap gap-7 items-center justify-between;
  }

  .leaflet-container {
    height: 300px;
  }

  .section {
    background: var(--background);
  }

  .content {
    background: var(--background);
    @apply w-full  overflow-auto;
  }
}

.block-container .btn-back {
  position: absolute;
  inset: 0;
  z-index: -1;
  width: inherit;
  height: inherit;
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: bottom right;
  transform: rotateZ(15deg);
  will-change: transform;
  box-shadow: 16px 0 40px #e4e4e4;
}

.block-container .btn-back-red {
  background: linear-gradient(135deg, #ff4b1f -20%, #ff9068 120%);
}

.block-container .btn-back-green {
  background: linear-gradient(135deg, #adfda2 -20%, #11d3f3 120%);
}

.block-container .btn-back-yellow {
  background: linear-gradient(135deg, #f7971e -20%, #ffd200 120%);
}

.block-container .btn-back-blue {
  background: linear-gradient(135deg, #0061ff -20%, #60efff 120%);
}

.block-container .btn-back-orange {
  background: linear-gradient(135deg, #ff0f7b -20%, #f89b29 120%);
}

.block-container .btn-back-pink {
  background: linear-gradient(135deg, #e81cff -20%, #40c9ff 120%);
}

.block-container .btn-back-black {
  background: linear-gradient(135deg, #0a1647 -20%, #e4e7e4 120%);
}

.block-container .btn-front {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: inherit;
  height: inherit;
  background-color: #ffffff33;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: top left;
  overflow: hidden;
}
